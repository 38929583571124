import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Masthead from "../components/strapi/structure/Masthead";
import Twocol from "../components/strapi/structure/TwoCol";
import Craftsmanship from "../components/strapi/structure/Craftsmanship";
import Outropiggy from "../components/strapi/structure/Outropiggy";

export default function HotTubs({ data }) {
  return (
    <Layout>
      <SEO title="Hot Tubs" />
      {data.allStrapiSeries300.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.s300_mh.mh_title}
            mhsubtitle={node.s300_mh.mh_subtitle}
            mhbackground={node.s300_mh.mh_bg.publicURL}
          />
          <Twocol
            title={node.s300_model320.txtimg_title}
            description={node.s300_model320.txtimg_description}
            blueTitle={node.s300_model320.txtimg_bluetitle}
            blueLink={node.s300_model320.txtimg_bluelink}
            order={node.s300_model320.txtimg_order}
            rowimg={node.s300_model320.txtimg_img.publicURL}
            start_price={node.s300_model320.start_price}
          />
          <Twocol
            title={node.s300_model340.txtimg_title}
            description={node.s300_model340.txtimg_description}
            blueTitle={node.s300_model340.txtimg_bluetitle}
            blueLink={node.s300_model340.txtimg_bluelink}
            bgColor={node.s300_model340.txtimg_bgcolor}
            order={node.s300_model340.txtimg_order}
            rowimg={node.s300_model340.txtimg_img.publicURL}
            start_price={node.s300_model340.start_price}
          />
          <Twocol
            title={node.s300_model350.txtimg_title}
            description={node.s300_model350.txtimg_description}
            blueTitle={node.s300_model350.txtimg_bluetitle}
            blueLink={node.s300_model350.txtimg_bluelink}
            bgColor={node.s300_model350.txtimg_bgcolor}
            order={node.s300_model350.txtimg_order}
            rowimg={node.s300_model350.txtimg_img.publicURL}
            start_price={node.s300_model350.start_price}
          />
          <Twocol
            title={node.s300_model360.txtimg_title}
            description={node.s300_model360.txtimg_description}
            blueTitle={node.s300_model360.txtimg_bluetitle}
            blueLink={node.s300_model360.txtimg_bluelink}
            bgColor={node.s300_model360.txtimg_bgcolor}
            order={node.s300_model360.txtimg_order}
            rowimg={node.s300_model360.txtimg_img.publicURL}
            start_price={node.s300_model360.start_price}
          />
          <Twocol
            title={node.s300_model380.txtimg_title}
            description={node.s300_model380.txtimg_description}
            blueTitle={node.s300_model380.txtimg_bluetitle}
            blueLink={node.s300_model380.txtimg_bluelink}
            bgColor={node.s300_model380.txtimg_bgcolor}
            order={node.s300_model380.txtimg_order}
            rowimg={node.s300_model380.txtimg_img.publicURL}
            start_price={node.s300_model380.start_price}
          />
          <Craftsmanship
            title={node.s300_craftsmanship.craft_title}
            description={node.s300_craftsmanship.craft_description}
          />
          <Outropiggy outro={node.s300_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query Series300 {
    allStrapiSeries300 {
      edges {
        node {
          id # id of the node
          s300_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          s300_model320 {
            id
            txtimg_title
            start_price
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          s300_model340 {
            id
            txtimg_title
            start_price
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_bgcolor
            txtimg_img {
              publicURL
            }
          }
          s300_model350 {
            id
            txtimg_title
            start_price
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_bgcolor
            txtimg_img {
              publicURL
            }
          }
          s300_model360 {
            id
            txtimg_title
            start_price
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_bgcolor
            txtimg_img {
              publicURL
            }
          }
          s300_model380 {
            id
            start_price
            txtimg_title
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_bgcolor
            txtimg_img {
              publicURL
            }
          }
          s300_craftsmanship {
            id
            craft_title
            craft_description
          }
          s300_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`;
